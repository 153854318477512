<template>
    <basic-page class="brand-page agrimontana" :pagedata="this.pageData" :bannerimg="bannersrc">
        <div class="container-beige intro container tabella-dati">
            <!-- <div class="logo-container" :style="{  backgroundImage: 'url(' + logoSource + ')' }"> -->
            <div class="logo">
                <img :src="logoSource" alt="">
            </div>
            <div class="list">
                <div data-aos="fade-in-custom" class="fade-in-custom" v-if="this.brandData.attributes">
                    <p class="label title-col">{{this.lang=='it' ? 'Anno di fondazione' : 'Founded'}}</p>
                    <p class="value">{{ this.brandData.attributes.field_year_of_establishment }}</p>
                </div>
                <div data-aos="fade-in-custom" class="fade-in-custom" v-if="this.brandData.attributes">
                    <p class="label title-col">{{ this.lang=='it' ? 'Prodotto' : 'Product'}}</p>
                    <p class="value">{{ this.brandData.attributes.field_product }}</p>
                </div>
                <div data-aos="fade-in-custom" class="fade-in-custom" v-if="this.brandData.attributes">
                    <p class="label title-col">{{ this.lang=='it' ? 'Nazione' : 'Country'}}</p>
                    <p class="value">{{ this.brandData.attributes.field_country }}</p>
                </div>
                <div data-aos="fade-in-custom" class="fade-in-custom" v-if="this.brandData.attributes">
                    <p class="label title-col">{{ this.lang=='it' ? 'Dipendenti' : 'Employees'}}</p>
                    <p class="value">{{ this.brandData.attributes.field_n_of_employees }}</p>
                </div>
            </div>
        </div>

        <div class="video" ref="videoContainer" v-if="videoSource">
            <div class="controls" ref="videoControls">
                <img src="../assets/play-button.svg" width="71" height="71" alt="">
                <p>{{ this.lang=='it' ? 'Guarda il video ' : 'Watch the video '}} <span>{{ this.lang=='it' ? 'qui.' : 'here.'}}</span></p>
            </div>
            <div class="embed-container">
                <iframe :src="this.videoSource + '?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'" 
                    frameborder="0" 
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen 
                    style="position:absolute;top:0;left:0;width:100%;height:100%;" 
                    title="Il Polo del Gusto presenta_ Agrimontana"></iframe>
            </div>
        </div>
        
        <div class="description container" v-if="this.brandData.field_brand_ceo">
            <div class="image">
                <img :src="ceosrc" alt="" width="300" height="425">
                <p v-if="this.brandData && this.brandData.relationships">{{ this.brandData.relationships.field_brand_ceo.data.meta.alt }}</p>
            </div>
            <div ref="text"
                v-if="this.brandData && this.brandData.attributes"
                v-html="this.brandData.attributes.field_description.processed">
            </div>
        </div>

        <!-- LAYOUT SCACCHIERA -->
        <div class="checkerboard">
            <div class="item " >
                <div class="data fade-in-block" data-aos="fade-in-custom-block"  >
                    <h4>{{ this.lang=='it' ? 'Qualità dirompente' : 'Disrupting quality'}}</h4>
                    <h2 class="num" >
                        <span>01</span>
                    </h2>
                    <h2 class="title" v-html="this.titolo_section1">
                    </h2>
                    <div v-if="this.brandData && this.brandData.attributes"
                        v-html="this.brandData.attributes.field_prodotti_unici.processed">
                    </div>
                </div>
                <div class="img fade-in-block"   data-aos="fade-in-custom-block" 
                    :style="{  backgroundImage: 'url(' + imageSource(1) + ')' }">
                </div>
            </div>
            <div class="item">
                <div class="data qualita fade-in-block"  data-aos="fade-in-custom-block">
                    <h4>{{ this.lang=='it' ? 'Qualità dirompente' : 'Disrupting quality'}}</h4>
                    <h2 class="num"  >
                        <span>02</span>
                    </h2>
                    <h2 class="title" v-html="this.titolo_section2">
                    </h2>
                    <div v-if="this.brandData && this.brandData.attributes"
                        v-html="this.brandData.attributes.field_materie_prime.processed">
                    </div>
                </div>
                <div class="img fade-in-block"  data-aos="fade-in-custom-block"
                    :style="{  backgroundImage: 'url(' + imageSource(2) + ')' }">
                </div>
            </div>
            <div class="item">
                <div class="data lavorazione fade-in-block"   data-aos="fade-in-custom-block">
                    <h4>{{ this.lang=='it' ? 'Qualità dirompente' : 'Disrupting quality'}}</h4>
                    <h2 class="num"  >
                        <span>03</span>
                    </h2>
                    <h2 class="title"  v-html="this.titolo_section3">
                    </h2>
                    <div v-if="this.brandData && this.brandData.attributes"
                        v-html="this.brandData.attributes.field_lavorazioni.processed">
                    </div>
                </div>
                <div class="img fade-in-block"  data-aos="fade-in-custom-block"
                    :style="{  backgroundImage: 'url(' + imageSource(3) + ')' }">
                </div>
            </div>
            <div class="item">
                <div class="data terra fade-in-block"  data-aos="fade-in-custom-block">
                    <h4>{{ this.lang=='it' ? 'Qualità dirompente' : 'Disrupting quality'}}</h4>
                    <h2 class="num"  >
                        <span>04</span>
                    </h2>
                    <h2 class="title"  v-html="this.titolo_section4">
                    </h2>
                    <div v-if="this.brandData && this.brandData.attributes"
                        v-html="this.brandData.attributes.field_rispetto_per_la_terra.processed">
                    </div>
                </div>
                <div class="img fade-in-block"   data-aos="fade-in-custom-block"
                    :style="{  backgroundImage: 'url(' + imageSource(4) + ')' }">
                </div>
            </div>
        </div>

        <!-- SLIDER PRODOTTI -->
        <div class="central carousel-prodotti" v-if="productData.length > 0">
            <div class="prev-arrow"><button @click="prev(2)">&#60;</button></div>
            <VueSlickCarousel ref="carousel-2" v-bind="carouselSettings" :arrows="false" :dots="false">
                <div class="slide" v-for="(p, index) in productData" :key="'product'+index">
                    <scheda-prodotto :prodData="p" :brandLogo="logoSource" :index="index+1" :total="productData.length">
                    </scheda-prodotto>
                </div>
            </VueSlickCarousel>
            <div class="next-arrow"><button @click="next(2)">&#62;</button></div>
        </div>

        <div class="container container-button">
            <div><div class="button-container">
            <a :href="buttonlink" target="_blank">
            <button class="button beige">{{ this.lang=='it' ? 'Vai al sito dell\'azienda' : 'Go to the company website'}}</button>
            </a>
            </div></div>
        </div>

        <!-- SEZIONE NEWS DEL BRAND -->
        <div class="news-page" v-if="newsData &&  this.lang != 'en' " >
            <div class="news-container container-beige container">
                <div>
                    <news-tile v-for="(n, index) in newsData" :key="'news'+index" :ndata="n"></news-tile>
                </div>
            </div>
        </div>

    </basic-page>
</template>

<script>
    import BasicPage from './BasicPage.vue'
    import SchedaProdotto from '../components/SchedaProdotto.vue'
    import VueSlickCarousel from 'vue-slick-carousel'
    import NewsTile from '../components/NewsTile.vue'
    import {
        fetchNodes,
        fetchSingleNode,
        fetchSinglePage,
        getTranslation
    } from '../libs/drupalClient'
    export default {
        components: {
            BasicPage,
            VueSlickCarousel,
            NewsTile,
            SchedaProdotto
        },
        name: 'achillea',
        data: () => {
            return {
                currentPageId: "3374365e-7f3d-4228-ae31-80a523f05d93",
                currentBrandId: "dc64f8ab-6aa0-414c-89cc-6280a8ace17b",
                pageData: {
                    attributes: {}
                },
                brandData: {},
                newsData: [],
                productData: [],
                p1: 0,
                p2: 0,
                mouseX: 0,
                mouseY: 0,
                carouselSettings: {
                    dots: false,
                    dotsClass: "slick-dots custom-dot-class",
                    edgeFriction: 0.35,
                    infinite: true,
                    speed: 500,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    accessibility: true
                }
            }
        },
        computed: {
            titolo_section1() {
                return this.lang=='it' ? "Valore percepibile,<br>prodotti unici" : "Recognisable value,<br>unique products"
            },
            titolo_section2() {
                return this.lang=='it' ? "L'eccellenza delle<br>materie prime" : "Excellence of raw materials"
            },
            titolo_section3() {
                return this.lang=='it' ? "L'arte delle<br>lavorazioni" : "Better production processes"
            },
            titolo_section4() {
                return this.lang=='it' ? "Il rispetto<br>per la terra" : "Respect for the Earth"
            },
            lang() {
                return this.$store.state.currentLang
            },
            buttonlink() {
                if(this.brandData && this.brandData.attributes) {
                    return this.brandData.attributes.field_link.uri
                } else 
                    return ''
            },
            logoSource() {
                if (this.brandData && this.brandData.field_brand_logo)
                    return process.env.VUE_APP_ENDPOINT + this.brandData.field_brand_logo.attributes.uri.url
                else
                    return ''
            },
            videoSource() {
                if (this.brandData.attributes && this.brandData.attributes.field_video) {
                    return this.brandData.attributes.field_video.uri
                } else {
                    return ''
                }
            },
            ceosrc() {
                if(this.brandData && this.brandData.field_brand_ceo) {
                    return process.env.VUE_APP_ENDPOINT + this.brandData.field_brand_ceo.attributes.uri.url
                } else {
                    return ''
                }
            },
            bannersrc() {
                if(this.brandData && this.brandData.field_brand_image) {
                    return process.env.VUE_APP_ENDPOINT + this.brandData.field_brand_image.attributes.uri.url
                } else {
                    return ''
                }
            }
        },
        methods: {
            prev(n) {
                this.$refs['carousel-' + n].prev()
            },
            next(n) {
                this.$refs['carousel-' + n].next()
            },
            imageSource(n) {
                return process.env.VUE_APP_ENDPOINT + this.brandData['field_0' + n + 'image'].attributes.uri.url
            },
            splittedTitle: function (value) {
                return value.replace(/(?![^<]*>)[^<]/g, c => `<span>${c}</span>\n`);
            },
            lerp(start, end, amt) {
                return (1 - amt) * start + amt * end
            },
            revealText() {
                this.p2 = this.p2 + 1;
                if (this.p2 > 20) {
                    this.p1 = this.p1 + 1;
                }

                //console.log(this.p1 + "-" + this.p2)
                this.$refs.text.style.setProperty('--p1', this.p1 + "%");
                this.$refs.text.style.setProperty('--p2', this.p2 + "%");
                //console.log('this.$refs.text', this.$refs.text);

                if (this.p2 < 200) {
                    requestAnimationFrame(this.revealText);
                }

            },
            mouseMove(event) {
                //let cPosleft = this.$refs.videoControls.getBoundingClientRect().left
                //let cPostop = this.$refs.videoControls.getBoundingClientRect().top

                /*
                this.mouseX = this.lerp(0, event.clientX, 0.058);
                this.mouseY = this.lerp(0, event.clientY, 0.058);
                */

                this.mouseX = event.clientX;
                this.mouseY = event.clientY;

                this.$refs.videoControls.style.transform = "translate3d(" + this.mouseX + "px," + this.mouseY +
                    "px, 0px)";

            }

        },
        created() {
            document.addEventListener('aos:in', ({
                detail
            }) => {

                if (detail.dataset.aos == "mask-reveal-text") {
                    // window.requestAnimationFrame(this.revealText)
                }

                //console.log('animated in', detail);
            });
        },
        mounted() {
            fetchSinglePage(this.currentPageId, this.lang)
            .then(res => {
                this.pageData = res[0]
            })

            fetchSingleNode('brand', this.currentBrandId, {
                include: ['field_brand_logo', 'field_brand_image', 'field_01image', 'field_02image',
                    'field_03image', 'field_04image', 'field_brand_ceo'
                ]
            }, this.lang).then(res => {
                this.brandData = res[0]
            })

            fetchNodes('product', {
                include: ['field_product_image', 'field_product_logo', 'field_ref_brand']
            }, this.lang).then(res => {
                this.productData = res.filter(p => p.field_ref_brand.id == this.currentBrandId)
            })

            fetchNodes('news', {
                include: ['field_news_image', 'field_ref_brand']
            }, this.lang).then(res => {
                this.newsData = res
                    .filter(p => {
                        if (p.field_ref_brand)
                            return p.field_ref_brand.id == this.currentBrandId
                        else
                            return false
                    })
                    .sort((a, b) => {
                        return a.attributes.field_news_date > b.attributes.field_news_date ? -1 : 1
                    })
            })
        },
        watch: {
            lang() {
                getTranslation(this.$route.name, this.lang)
                .then(res => {
                    this.$router.push({path: res})
                })

                fetchSingleNode('brand', this.currentBrandId, {
                include: ['field_brand_logo', 'field_brand_image', 'field_01image', 'field_02image',
                    'field_03image', 'field_04image', 'field_brand_ceo'
                ]
                }, this.lang).then(res => {
                    this.brandData = res[0]
                })

                fetchNodes('product', {
                    include: ['field_product_image', 'field_product_logo', 'field_ref_brand']
                }, this.lang).then(res => {
                    this.productData = res.filter(p => p.field_ref_brand.id == this.currentBrandId)
                })

                fetchNodes('news', {
                    include: ['field_news_image', 'field_ref_brand']
                }, this.lang).then(res => {
                    this.newsData = res
                        .filter(p => {
                            if (p.field_ref_brand)
                                return p.field_ref_brand.id == this.currentBrandId
                            else
                                return false
                        })
                        .sort((a, b) => {
                            return a.attributes.field_news_date > b.attributes.field_news_date ? -1 : 1
                        })
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../node_modules/vue-slick-carousel/dist/vue-slick-carousel.css';
    @import '../../node_modules/vue-slick-carousel/dist/vue-slick-carousel-theme.css';

    .central {
        position: relative;
    }

    .carousel-prodotti {
        margin-top: 6rem;
    }
</style>